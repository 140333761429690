import { currencies } from "@/data/currencies";
import { format, differenceInDays } from "date-fns";
import { capitalize, replace, round, split, trim } from "lodash-es"; 

export interface PriceDetail {
  amount: any;
  amount_type: string;
  currency: string;
  included: boolean;
  mandatory: boolean;
  name: string;
}

export interface PriceDetails {
  [key: string]: PriceDetail[];
}

export interface Room {
  price_details: PriceDetails;
  rate_comments?: {
    [key: string]: any;
  };
  [key: string]: any;
}
interface Person {
  title: string;
  name: string;
  surname: string;
  type: string;
  age?: number; // Optional age field for children
}

export const calculateMandatoryTaxes = (room: Room) => {
  const priceDetails = room?.price_details;
  const mandatoryTaxText = room?.rate_comments?.MandatoryTax;
  let total: number = 0;
  const mandatoryTax: PriceDetail[] = [];

  if (!priceDetails) return { total, mandatoryTax };

  Object.keys(priceDetails).forEach((key) => {
    const detailArray = priceDetails[key];
    const excluded = detailArray.filter(
      (detail) => detail.included === false  && detail.mandatory === true
    );
    mandatoryTax.push(...excluded);
    total += excluded.reduce((sum, detail) => sum + detail?.amount, 0);
  });

  return { total, mandatoryTax, mandatoryTaxText };
};
export const getHotelCharges = (room: Room) => {
  const priceDetails = room?.price_details;
  let total: number = 0;
  const hotelCharges: PriceDetail[] = [];

  if (!priceDetails) return { total, hotelCharges };

  Object.keys(priceDetails).forEach((key) => {
    const detailArray = priceDetails[key];
    const excluded = detailArray.filter(
      (detail) => detail.included === false // && detail.mandatory === true
    );
    hotelCharges.push(...excluded);
    total += excluded.reduce((sum, detail) => sum + detail?.amount, 0);
  });

  return { total, hotelCharges };
};
export const getHotelChargesOther = (room: Room) => {
  const priceDetails = room?.breakdown;
  let total: number = 0;
  const hotelCharges: PriceDetail[] = [];

  if (!priceDetails) return { total, hotelCharges };

  Object.keys(priceDetails).forEach((key) => {
    const detailArray = priceDetails[key];
    const excluded = detailArray.filter(
      (detail: any) => detail.included === false // && detail.mandatory === true
    );
    hotelCharges.push(...excluded);
    total += excluded.reduce((sum: any, detail: any) => sum + detail?.amount, 0);
  });

  return { total, hotelCharges };
};

export const createQueryString = (payload: any) => {
 
  // const encodedPayload = decodeURIComponent(payloadString);
  const newString = {
    ...payload,
    location: {
      ...payload?.location,
      name: payload?.location?.name.replace(/&/g, "and"),
      label:  payload?.location?.label.replace(/&/g, "and"),
    },
  };
  const payloadString = JSON.stringify(newString);

  return payloadString.toString();
  // return newString.toString();
};

export const generatePersons = (
  count: number,
  type: string,
  ages: number[] = []
): Person[] => {
  return Array.from({ length: count }, (_, index) => ({
    title: "",
    name: "",
    surname: "",
    type,
    ...(type === "CH" && { age: ages[index] }),
  }));
};

export const getFinalPrice = (room: Room, isNewRate = false, markup?: any) => {
  if (isNewRate == false) {
    const { currency, price } = room;
    const priceAsNumber = parseFloat(price as unknown as string); // Convert price to number if needed
    const amount = priceAsNumber;
    return {
      currency,
      price,
      amount,
    };
  } else {
    const { currency, price } = room;
    const priceAsNumber = parseFloat(price as unknown as string); // Convert price to number if needed
    const amount =
      priceAsNumber +
      (priceAsNumber * (markup || process.env.NEXT_PUBLIC_MARKUP_PERCENTAGE)) /
        100; // Adding 10% markup
    return {
      currency,
      price,
      amount: round(amount),
    };
  }
};

export const paginationInput = {
  nextLabel: ">",
  previousLabel: "<",
  breakLabel: "...",
  pageRangeDisplayed: 4,
  marginPagesDisplayed: 1,
  renderOnZeroPageCount: null,
  pageClassName: "page-item",
  pageLinkClassName: "page-link",
  previousClassName: "page-item",
  previousLinkClassName: "page-link",
  nextClassName: "page-item",
  nextLinkClassName: "page-link",
  breakClassName: "page-item",
  breakLinkClassName: "page-link",
  activeClassName: "active",
};

export const getCancellationPolicyText = (room: Room) => {
  // console.log("getCancellationPolicyText", room)
  const { cancellation_policy } = room;
  const { under_cancellation, cancel_by_date, details, amount_type } =
    cancellation_policy;
  let txt = "";
  if (under_cancellation == false && cancel_by_date) {
    txt = `Free cancellation till ${format(
      new Date(cancel_by_date),
      "dd MMM yyyy HH:mm"
    )}`;
  }
  // if (under_cancellation == true && details?.length > 0 && amount_type == "value") {
  //   txt = `Free cancellation Before ${format(
  //     new Date(details[0]?.from),
  //     "dd MMM yyyy HH:mm"
  //   )}`;
  // }
  return txt;
};

export const formatDate = (date: string | any, formatType: string) => {
  if (date) {
    return format(new Date(date), formatType);
  }
};

export const formatPaxComments = (paxComments: any) => {
  const capitalizedPhrase = capitalize(paxComments);
  const txt = replace(capitalizedPhrase, /_/g, " ");
  return txt;
};

export const getMarkupPrice = (price: any, commission?: any) => {
  const appCommission = commission || process.env.NEXT_PUBLIC_MARKUP_PERCENTAGE;
  const priceAsNumber = parseFloat(price as unknown as string); // Convert price to number if needed
  // const amount = priceAsNumber + priceAsNumber * appCommission; // Adding 10% markup
  const amount = priceAsNumber + (priceAsNumber * appCommission) / 100;
  return round(amount);
};

export const getMyRoomSummary = (hotel: any) => {
  const {
    no_of_nights,
    no_of_rooms,
    no_of_adults,
    no_of_children,
    no_of_infants,
  } = hotel || {};
  const totalChildren = no_of_children || 0;
  const totalInfants = no_of_infants || 0;
  const totalRooms = no_of_rooms || 0;
  const totalAdults = no_of_adults || 0;
  const childrenPart =
    totalChildren > 0
      ? `and ${totalChildren} Child${totalChildren > 1 ? "ren" : ""}`
      : "";
  const infantsPart =
    totalInfants > 0
      ? `,${totalInfants}  Infant${totalInfants > 1 ? "s" : ""}`
      : "";
  return `${totalRooms} Room${totalRooms > 1 ? "s" : ""},${totalAdults} Adult${
    totalAdults > 1 ? "s" : ""
  }${infantsPart} ${childrenPart}`;
};

export const transformGuests = (guests: any) => {
  return guests.map((guest: any) => ({
    ...guest,
    children_ages: guest?.children_ages?.filter((age: any) => age !== 0),
  }));
};



export const addSpaces = (input: string) => {
  return input.replace(/([A-Z])/g, " $1").trim();
};


export const subtractDays = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};


export const truncateMultiLineText = (text: string, maxLines: number, maxCharsPerLine: number) => {
  const maxLength = maxLines * maxCharsPerLine;
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
};

export const getWordsCount = (input: string): number => {
  // Step 1: Remove HTML tags using a custom regex
  const plainText = replace(input, /<\/?[^>]+(>|$)/g, "");
  // console.log("plainText", plainText)
  // Step 2: Normalize whitespace (convert multiple spaces and newlines to a single space)
  const normalizedText = trim(replace(plainText, /\s+/g, " "));
  // console.log("normalizedText", normalizedText)
  // Step 3: Split by spaces and count words
  const words = split(normalizedText, " ");
 
  return words?.length;
  // Step 4: Filter out empty strings and return the word count
  // return _.filter(words, Boolean).length;
};

export const getCurrencyData = (currencyCode: string) => {
  const currencyData: any = currencies?.filter(
    (item) => item.code === currencyCode
  );
  return currencyData?.length > 0 ? currencyData[0] : null;
  // return filter(words, Boolean).length;
};

export const convertToNights = (from: string, to: string): number => {
    const checkInDate = new Date(from); // Parse ISO date string (YYYY-MM-DD)
    const checkOutDate = new Date(to); // Parse ISO date string (YYYY-MM-DD)
    const nights = differenceInDays(checkOutDate, checkInDate);
    return nights;
}

export const getTotalAmount = (bookingData: any) => {
  const credits_applied: number =  bookingData?.credits_applied ?  parseFloat(bookingData?.credits_applied) : 0;
    const earned_credits_applied: number = bookingData?.earned_credits_applied ?  parseFloat(bookingData?.earned_credits_applied) : 0;
    const total_paid_amount =
      bookingData?.hotel?.booking_items[0]?.price_markup - credits_applied - earned_credits_applied;

    return {
      credits_applied,
      total_paid_amount,
      earned_credits_applied,
    };
}

export const getTotalProcessingFee = (total_paid_amount: any) => {
  const amount = total_paid_amount
  const fee = 3.5;
  const total = (amount * fee) / 100;
  return round(total, 2);
};