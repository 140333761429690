import { showToast } from "@/app/CustomToastProvider";
import { createAppSlice } from "@/lib/createAppSlice";
import NavigationService from "@/utils/NavigationService";
import { getMarkupPrice } from "@/utils/helper";
import { getFetchApi, postApi } from "@/utils/http";
import { type PayloadAction } from "@reduxjs/toolkit";
import round from 'lodash-es/round';

interface BookingPayload {
  [key: string | number]: any;
}

export interface HotelSliceState {
  payload: Record<string, any> | null;
  loading: boolean;
  loadingMore: boolean;
  hotelLoading: boolean;
  bookingLoading: boolean;
  searchLoading: boolean;
  promoCodeLoading: boolean;
  hotels: { [key: string | number]: any };
  hotel: { [key: string | number]: any };
  error: string | null;
  searchPayload: { [key: number | string]: any };
  bookingPayload: { [key: number | string]: any };
  noAvailability: boolean;
  myCredits: any;
  getHotelsProgress: number;
}

const initialState: HotelSliceState = {
  payload: {},
  searchPayload: {},
  bookingPayload: {},
  searchLoading: false,
  loading: false,
  loadingMore: false,
  hotelLoading: false,
  bookingLoading: false,
  promoCodeLoading: false,
  hotels: {},
  hotel: {},
  error: null,
  noAvailability: false,
  myCredits: {},
  getHotelsProgress: 0,
};

export const hotelSlice = createAppSlice({
  name: "hotel",
  initialState,
  reducers: (create) => ({
    resetCredits: create.reducer((state, { payload }: PayloadAction<any>) => {
      state.myCredits = payload?.data;
    }),
    saveSearchPayload: create.asyncThunk(
      async (payload: any) => {

        // console.log("API Inside reserveRoom:", payload);
        try {
          // Simulate an API call or async process (you can replace this with actual API call)
          const response = await new Promise((resolve) =>
            setTimeout(() => resolve(payload), 100)
          );

          return response;
        } catch (error: any) {
          return error;
        }
      },
      {
        pending: (state) => {
          state.searchLoading = true;
          state.error = null; // Clear any previous error
        },
        fulfilled: (state, { payload, meta }) => {
          const code = payload?.location?.code;
          // Ensure that the state has a structure for the given hotel code
          if (!state.searchPayload[code] && code) {
            state.searchPayload[code] = {};
          }

          state.searchPayload[payload?.location?.code] = payload;
          state.searchLoading = false;
        },
        rejected: (state, action) => {
          state.searchLoading = false;
          state.error = action.error?.message || "Internal Server Error";
          showToast("Internal Server Error", "danger");
        },
      }
    ),
    saveBookingPayload: create.reducer((state, { payload }: any) => {
      state.bookingPayload[payload?.payload?.hotel_code] = payload;
    }),
    updateBookingPayload: create.reducer(
      (
        state,
        {
          payload,
        }: PayloadAction<{ payload: BookingPayload; hotel_code: string }>
      ) => {
        const { first_name, last_name, email } = payload?.payload;
        const hotelCode = payload?.hotel_code;

        // Ensure that the state has a structure for the given hotel code
        if (!state.bookingPayload[hotelCode]) {
          state.bookingPayload[hotelCode] = { payload: { holder: {} } };
        }

        const holder =
          state.bookingPayload[payload?.hotel_code]?.payload?.holder;
        if (first_name) holder.name = first_name;
        if (last_name) holder.surname = last_name;
        if (email) holder.email = email;
      }
    ),
    saveNoAvailability: create.reducer(
      (state, { payload }: PayloadAction<boolean>) => {
        // console.log("saveNoAvailability", payload);
        state.noAvailability = payload;
      }
    ),
    updateRecheckRates: create.reducer(
      (state, { payload }: PayloadAction<any>) => {
        state.bookingPayload[payload?.hotel?.hotel_code].rates = payload;
      }
    ),
    deleteBookingPayload: create.reducer(
      (state, { payload }: PayloadAction<any>) => {
        const bookingId = payload; // e.g., 123456
        if (state.bookingPayload[bookingId]) {
          delete state.bookingPayload[bookingId];
        }
      }
    ),
    setCurrentBatchIndex: create.reducer(
      (state, { payload }: PayloadAction<any>): any => {
        // console.log("setCurrentBatchIndex", payload)
        const { hotel_code, index } = payload; // e.g., 123456
        if (state.hotels[hotel_code]) {
          state.hotels[hotel_code].currentBatchIndex = index;
        }
      }
    ),
    setSelectedBatch: create.reducer(
      (state, { payload }: PayloadAction<any>): any => {
        // console.log("setSelectedBatch", payload)
        const { hotel_code, batch } = payload; // e.g., 123456
        if (state.hotels[hotel_code]) {
          state.hotels[hotel_code].selectedBatch = batch;
        }
      }
    ),
    setHotelAppliedFilters: create.reducer(
      (state, { payload }: PayloadAction<any>): any => {
        const { hotel_code, filters } = payload; // e.g., 123456
        if (state.hotels[hotel_code]) {
          state.hotels[hotel_code].appliedFilters = filters;
        }
      }
    ),
    setProgress: create.reducer(
      (state, { payload }: PayloadAction<number>) => {
        state.getHotelsProgress = payload;
      }
    ),
    getHotels: create.asyncThunk(
      async (payload: any, { dispatch }) => {
        const { method, query, ...rest } = payload?.payload;
        const newPayload = {
          url: payload?.url,
          payload: rest,
        };
        try {
          dispatch(setProgress(1)); // Start progress at 10%
          
          let response;
          if (method && method === "batch") {
            response = await postApi(newPayload, (progress: number) => {
              console.log("Percentage", progress)
              dispatch(setProgress(progress)); // Update progress dynamically
            });
          } else {
            response = await getFetchApi(newPayload, undefined, undefined, (progress: number) => {
              console.log("Percentage", progress)
              dispatch(setProgress(progress)); // Update progress dynamically
            });
          }
          
          dispatch(setProgress(100)); // Set to 100% on completion
          setTimeout(() => {
            dispatch(setProgress(0));
          }, 1000);
          return response;
        } catch (e) {
          dispatch(setProgress(0)); // Reset progress on error
          throw e;
        }

      },
      {
        pending: (state) => {
          state.loading = true;
          state.error = null; // Clear any previous error
        },
        fulfilled: (state, { payload, meta }) => {
          const { status, message, data } = payload;

          if (status == 200) {
            const { hotels } = data;
            const { code, latitude, longitude, type, reset, city_code } = meta?.arg?.payload;
            const uniqueInclusions = new Set<string>();
            const allMealPlan = new Set<string>();
            const updatedData: any[] =
              hotels?.length > 0 &&
              hotels?.map((hotel: any) => {
                // Extract and add `other_inclusions` to the Set for unique values
                hotel?.min_rate?.other_inclusions?.forEach(
                  (inclusion: string) => uniqueInclusions.add(inclusion)
                );
                hotel?.min_rate?.boarding_details?.forEach((meal: string) =>
                  allMealPlan.add(meal)
                );
                // console.log("Markup", hotel?.markup);
                const incrementedPrice = getMarkupPrice(
                  hotel?.min_rate?.price,
                  hotel?.markup
                ); // Calculate 10% increment
                // console.log("Markup incrementedPrice", incrementedPrice);
                // Check if amount_type is 'value' and then map through the details array to apply the 10% markup
                const updatedDetails =
                  hotel?.min_rate?.cancellation_policy?.amount_type === "value"
                    ? hotel?.min_rate?.cancellation_policy?.details?.map(
                        (detail: any) => {
                          const priceMarkup = getMarkupPrice(
                            detail?.flat_fee,
                            hotel?.markup
                          ); // 10% markup on flat_fee

                          return {
                            ...detail,
                            price_markup: priceMarkup,
                          };
                        }
                      )
                    : hotel?.min_rate?.cancellation_policy?.details; // Keep details unchanged if amount_type is not 'value'

                // Calculate the markup for the no_show_fee only if amount_type is 'value'
                const noShowFeeMarkup =
                  hotel?.min_rate?.cancellation_policy?.amount_type === "value"
                    ? getMarkupPrice(
                        hotel?.min_rate?.cancellation_policy?.no_show_fee
                          ?.flat_fee,
                        hotel?.markup
                      )
                    : hotel?.min_rate?.cancellation_policy?.no_show_fee
                        ?.flat_fee;

                return {
                  ...hotel,
                  min_rate: {
                    ...hotel?.min_rate,
                    priceWithMarkup: round(incrementedPrice),
                    cancellation_policy: {
                      ...hotel?.min_rate?.cancellation_policy,
                      details: updatedDetails, // Update details only when amount_type is 'value'
                      no_show_fee: {
                        ...hotel?.min_rate?.cancellation_policy?.no_show_fee,
                        price_markup:
                          hotel?.min_rate?.cancellation_policy?.amount_type ===
                          "value"
                            ? noShowFeeMarkup
                            : undefined, // Add price markup to no_show_fee only when amount_type is 'value'
                      },
                    },
                  },
                };
              });
            const mergedData = { ...data, hotels: updatedData };
            // console.log("mergedData", mergedData);
            // Convert the Set to an array to get all unique other_inclusions
            const uniqueInclusionsArray = [
              { label: "Select All", value: "" }, // Add "Select All" as the first option
              ...Array.from(uniqueInclusions)
                .map((inclusion) => ({
                  label: inclusion,
                  value: inclusion,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)), // Sort alphabetically by label
            ];

            const uniqueMealPlanArray = [
              // { label: "Select All", value: "" }, // Add "Select All" as the first option
              ...Array.from(allMealPlan)
                .map((item) => ({
                  label: item,
                  value: item,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)), // Sort alphabetically by label
            ];

            // state.allAmenities = uniqueInclusionsArray || [];
            // state.allMealPlan = uniqueMealPlanArray || [];
            

            if (data?.no_availability && data?.no_availability == true) {
              state.loading = false;
              state.hotels[code] = {};
            }
            const { city, city_code: city_code_internal, country } =
              (hotels?.length > 0 && hotels[0]) || {};
            // console.log("Meta",  type, city, city_code, country, latitude && longitude);
            if(code && !latitude && !longitude && reset && reset == true && city_code){
              state.hotels[city_code] = mergedData || [];
              state.hotels[city_code].allAmenities = uniqueInclusionsArray;
              state.hotels[city_code].allMealPlan = uniqueMealPlanArray;
            }
            else if (code && !latitude && !longitude) {
              state.hotels[code] = mergedData || [];
              state.hotels[code].allAmenities = uniqueInclusionsArray;
              state.hotels[code].allMealPlan = uniqueMealPlanArray;
            }
            if (type == "geolocation" && city_code_internal && latitude && longitude) {
              state.hotels[city_code_internal] = mergedData || [];
              state.hotels[city_code_internal].allAmenities = uniqueInclusionsArray;
              state.hotels[city_code_internal].allMealPlan = uniqueMealPlanArray;

              const payloadQuery = meta?.arg?.payload.query;
              const payloadString = {
                ...payloadQuery,
                location: {
                  name: city.replace(/&/g, "and"),
                  code: city_code_internal,
                  label: country,
                  type: "city",
                },
                type: "geolocation",
                latitude,
                longitude,
              };
              const encodedPayload = decodeURIComponent(
                JSON.stringify(payloadString)
              );
              const queryString = encodedPayload.toString();
              console.log("search updated", payloadString);

              state.searchPayload[city_code_internal] = payloadString;
              setTimeout(() => {
                NavigationService.navigate(
                  `/hotels?payload=${queryString}&geolocation=${true}`
                );
              }, 1000);
            }
          } else {
            showToast(message, "danger");
          }
          state.loading = false;
        },
        rejected: (state, action) => {
          state.loading = false;
          state.error = action.error?.message || "Failed to fetch hotels";
          showToast("Failed to fetch hotels", "danger");
        },
      }
    ),
    getHotel: create.asyncThunk(
      async (payload: any) => {
        try {
          const response = await getFetchApi(payload);
          return response;
        } catch (e) {
          throw e;
        }
      },
      {
        pending: (state) => {
          state.hotelLoading = true;
          state.error = null; // Clear any previous error
        },
        fulfilled: (state, { payload, meta }) => {
          const { status, message, data } = payload;
          if (
            status === 200 &&
            data?.no_availability &&
            data?.no_availability == true &&
            !data?.search_id
          ) {
            showToast(message, "danger");
            state.noAvailability = true; // Set the flag to true
          }
          if (status === 200 && data?.search_id && data?.hotel) {
            const { hotel_code } = meta?.arg?.payload;
            const markup = data?.hotel?.markup;
            const updatedData: any[] = data?.hotel?.rates?.map((hotel: any) => {
              const incrementedPrice = getMarkupPrice(hotel?.price, markup);

              const updatedDetails =
                hotel?.cancellation_policy?.amount_type === "value"
                  ? hotel?.cancellation_policy?.details?.map((detail: any) => {
                      const priceMarkup = getMarkupPrice(
                        detail?.flat_fee,
                        markup
                      ); // 10% markup on flat_fee

                      return {
                        ...detail,
                        price_markup: priceMarkup,
                      };
                    })
                  : hotel?.cancellation_policy?.details; // Keep details unchanged if amount_type is not 'value'

              // Calculate the markup for the no_show_fee only if amount_type is 'value'
              const noShowFeeMarkup =
                hotel?.cancellation_policy?.amount_type === "value"
                  ? getMarkupPrice(
                      hotel?.cancellation_policy?.no_show_fee?.flat_fee,
                      markup
                    )
                  : hotel?.cancellation_policy?.no_show_fee?.flat_fee;

              return {
                ...hotel,
                priceWithMarkup: incrementedPrice,
                cancellation_policy: {
                  ...hotel?.cancellation_policy,
                  details: updatedDetails, // Update details only when amount_type is 'value'
                  no_show_fee: {
                    ...hotel?.cancellation_policy?.no_show_fee,
                    price_markup:
                      hotel?.cancellation_policy?.amount_type === "value"
                        ? noShowFeeMarkup
                        : undefined, // Add price markup to no_show_fee only when amount_type is 'value'
                  },
                },
              };
            });
            const mergedData = {
              ...data,
              hotel: {
                ...data?.hotel,
                rates: updatedData,
              },
            };

            console.log("mergedData", mergedData);
            state.hotel[hotel_code] = mergedData;
          }
          state.hotelLoading = false;
        },
        rejected: (state, action) => {
          state.hotelLoading = false;
          state.error = action.error?.message || "Internal Server Error";
          showToast("Internal Server Error", "danger");
        },
      }
    ),
    reserveRoom: create.asyncThunk(
      async (payload: any) => {
        console.log("API Inside reserveRoom:", payload);
        try {
          const response = await postApi(payload);
          return response;
        } catch (e) {
          throw e;
        }
      },
      {
        pending: (state) => {
          state.bookingLoading = true;
          state.error = null; // Clear any previous error
        },
        fulfilled: (state, { payload, meta }) => {
          const { status, message, data } = payload;
          if (status === 400) {
            showToast(message, "danger");
          } else {
            if (status == 200) {
            }
          }
          state.bookingLoading = false;
        },
        rejected: (state, action) => {
          state.bookingLoading = false;
          state.error = action.error?.message || "Internal Server Error";
          showToast("Internal Server Error", "danger");
        },
      }
    ),
    applyPromoCode: create.asyncThunk(
      async (payload: any) => {
        console.log("API Inside PromoCode:", payload);
        try {
          const response = await postApi(payload);
          return response;
        } catch (e) {
          throw e;
        }
      },
      {
        pending: (state) => {
          state.promoCodeLoading = true;
          state.error = null; // Clear any previous error
        },
        fulfilled: (state, { payload, meta }) => {
          const { status, message, data } = payload;
          if (status === 400) {
            showToast(message, "danger");
          } else {
            if (status == 200) {
            }
          }
          state.promoCodeLoading = false;
        },
        rejected: (state, action) => {
          state.promoCodeLoading = false;
          state.error = action.error?.message || "Internal Server Error";
          showToast("Internal Server Error", "danger");
        },
      }
    ),
    loadMoreHotel: create.asyncThunk(
      async (payload: any) => {
        const { method, query, ...rest } = payload?.payload;
        const newPayload = {
          url: payload?.url,
          payload: rest,
        };
        try {
          const response = await postApi(newPayload);
          // return  {
          //   status: 200,
          //   message: "NO AVAILABILITY",
          //   data: {
          //     hotels:[],
          //     no_availability: true
          //   },
          // }
          return response;
        } catch (e) {
          throw e;
        }
      },
      {
        pending: (state) => {
          state.loadingMore = true;
          state.error = null; // Clear any previous error
        },
        fulfilled: (state, { payload, meta }) => {

          const { status, message, data } = payload;
          if (status == 200) {
            const hotels = data && data?.hotels || [];
            const { code, latitude, longitude, type } = meta?.arg?.payload;
            const uniqueInclusions = new Set<string>();
            const allMealPlan = new Set<string>();
            const updatedData: any[] =
              hotels?.length > 0 &&
              hotels?.map((hotel: any) => {
                // Extract and add `other_inclusions` to the Set for unique values
                hotel?.min_rate?.other_inclusions?.forEach(
                  (inclusion: string) => uniqueInclusions.add(inclusion)
                );
                hotel?.min_rate?.boarding_details?.forEach((meal: string) =>
                  allMealPlan.add(meal)
                );
                // console.log("Markup", hotel?.markup);
                const incrementedPrice = getMarkupPrice(
                  hotel?.min_rate?.price,
                  hotel?.markup
                ); // Calculate 10% increment
                // console.log("Markup incrementedPrice", incrementedPrice);
                // Check if amount_type is 'value' and then map through the details array to apply the 10% markup
                const updatedDetails =
                  hotel?.min_rate?.cancellation_policy?.amount_type === "value"
                    ? hotel?.min_rate?.cancellation_policy?.details?.map(
                        (detail: any) => {
                          const priceMarkup = getMarkupPrice(
                            detail?.flat_fee,
                            hotel?.markup
                          ); // 10% markup on flat_fee

                          return {
                            ...detail,
                            price_markup: priceMarkup,
                          };
                        }
                      )
                    : hotel?.min_rate?.cancellation_policy?.details; // Keep details unchanged if amount_type is not 'value'

                // Calculate the markup for the no_show_fee only if amount_type is 'value'
                const noShowFeeMarkup =
                  hotel?.min_rate?.cancellation_policy?.amount_type === "value"
                    ? getMarkupPrice(
                        hotel?.min_rate?.cancellation_policy?.no_show_fee
                          ?.flat_fee,
                        hotel?.markup
                      )
                    : hotel?.min_rate?.cancellation_policy?.no_show_fee
                        ?.flat_fee;

                return {
                  ...hotel,
                  min_rate: {
                    ...hotel?.min_rate,
                    priceWithMarkup: round(incrementedPrice),
                    cancellation_policy: {
                      ...hotel?.min_rate?.cancellation_policy,
                      details: updatedDetails, // Update details only when amount_type is 'value'
                      no_show_fee: {
                        ...hotel?.min_rate?.cancellation_policy?.no_show_fee,
                        price_markup:
                          hotel?.min_rate?.cancellation_policy?.amount_type ===
                          "value"
                            ? noShowFeeMarkup
                            : undefined, // Add price markup to no_show_fee only when amount_type is 'value'
                      },
                    },
                  },
                };
              });
            const mergedData = { ...data, hotels: updatedData || []};
            // console.log("Load More Merged Data", mergedData);
            // Convert the Set to an array to get all unique other_inclusions
            const uniqueInclusionsArray = [
              ...Array.from(uniqueInclusions)
                .map((inclusion) => ({
                  label: inclusion,
                  value: inclusion,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)), // Sort alphabetically by label
            ];
            const uniqueMealPlanArray = [
              ...Array.from(allMealPlan)
                .map((item) => ({
                  label: item,
                  value: item,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)), // Sort alphabetically by label
            ];

            const allAmenities = [
              ...state.hotels[code].allAmenities,
              ...uniqueInclusionsArray.filter(
                newItem =>
                  !state.hotels[code].allAmenities.some(
                    (existingItem: any) => existingItem.value === newItem.value
                  )
              ),
            ];
            const allMeals = [
              ...state.hotels[code].allMealPlan,
              ...uniqueMealPlanArray.filter(
                newItem =>
                  !state.hotels[code].allMealPlan.some(
                    (existingItem: any) => existingItem.value === newItem.value
                  )
              ),
            ]
            console.log("before", allMeals)
            state.hotels[code].allAmenities = allAmenities;
            state.hotels[code].allMealPlan = allMeals;


            if (code && !latitude && !longitude && hotels != "" && mergedData?.hotels != "" && mergedData?.hotels != null) {
              const stateToUpdate = [...mergedData.hotels];
              const no_of_hotels = data?.no_of_hotels;
              
              if(state.hotels[code].hotels){
                state.hotels[code].hotels = [...state.hotels[code].hotels, ...stateToUpdate];
              }
              if (state.hotels[code]?.no_of_hotels) {
                state.hotels[code].no_of_hotels += no_of_hotels
              }
            }
          } else {
            showToast(message, "danger");
          }
          state.loadingMore = false;
        },
        rejected: (state, action) => {
          state.loadingMore = false;
          state.error = action?.error?.message || "Failed to fetch hotels";
          showToast("Failed to fetch hotels", "danger");
        },
      }
    ),
    getMyCredits: create.asyncThunk(
      async (payload: any) => {
        console.log("API Inside getMyCredits:", payload);
        try {
          const response = await getFetchApi(payload);
          return response;
        } catch (e) {
          throw e;
        }
      },
      {
        pending: (state) => {
          
          state.error = null; // Clear any previous error
        },
        fulfilled: (state, { payload, meta }) => {
          console.log("API meta:", meta);
          const { status, message, data } = payload;
          if (status === 400) {
            showToast(message, "danger");
          } else {
            if (status == 200) {
              state.myCredits = data;
            }
          }
        },
        rejected: (state, action) => {
          state.error = action.error?.message || "Internal Server Error";
          showToast("Internal Server Error", "danger");
        },
      }
    ),
  }),
  selectors: {
    getSearchPayload: (_) => _?.searchPayload,
    getBookingPayload: (_) => _?.bookingPayload,
    hotels: (_) => _?.hotels,
    hotel: (_) => _?.hotel.data,
    loading: (_) => _?.loading,
    error: (_) => _?.error,
    getHotelLoading: (_) => _?.hotelLoading,
    getHotelDetail: (_) => _?.hotel.data,
    getNoAvailability: (_) => _?.noAvailability,
    getBookingLoading: (_) => _?.bookingLoading,
    getSearchLoading: (_) => _?.searchLoading,
    viewHotel: (_) => _?.hotel,
    getLoadingMore: (_) => _?.loadingMore,
    getCredits: (_) => _?.myCredits,
    getHotelsProgress:(_) => _?.getHotelsProgress
  },
});

export const {
  saveSearchPayload,
  saveBookingPayload,
  getHotels,
  getHotel,
  saveNoAvailability,
  reserveRoom,
  updateRecheckRates,
  deleteBookingPayload,
  updateBookingPayload,
  applyPromoCode,
  loadMoreHotel,
  setCurrentBatchIndex,
  setSelectedBatch,
  setHotelAppliedFilters,
  getMyCredits,
  setProgress,
  resetCredits
} = hotelSlice.actions;
export const {
  getSearchPayload,
  getBookingPayload,
  hotels,
  hotel,
  loading,
  error,
  getHotelLoading,
  getHotelDetail,
  getNoAvailability,
  getBookingLoading,
  getSearchLoading,
  viewHotel,
  getLoadingMore,
  getCredits,
  getHotelsProgress
} = hotelSlice.selectors;
